import React from 'react';
import SvgLines from 'react-mt-svg-lines'; 
import logo from './logo.svg'
import './main.css';

function App() {
  return (
    <div className="main">
      <header className="centre">
      	<SvgLines className="logo" animate={ true } duration={ 2000 }>
		  <svg viewBox="0 0 600 75">
			<defs>
			  <linearGradient id="LinearGradient" x1="0" y1="0" x2="100%" y2="100%" gradientUnits="userSpaceOnUse">
			    <stop stop-color="#00dbde" offset="0%"/>
			    <stop stop-color="#fc00ff" offset="100%"/>
			  </linearGradient>
			</defs>
            <path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-123.462+55.8289M61.9983+27.4211C60.2831+24.5804+57.9247+22.3828+54.9231+20.7212C51.9216+19.1132+48.4376+18.3092+44.5784+18.3092L29.7314+18.3092L29.7314+20.7748L44.364+20.7748C47.7944+20.7748+50.8496+21.4716+53.5295+22.8652C56.1559+24.2588+58.1927+26.1884+59.6399+28.6003C61.0335+31.0659+61.7839+33.9067+61.7839+37.0691C61.7839+40.285+61.0335+43.0722+59.6399+45.5378C58.1927+48.0034+56.1559+49.933+53.5295+51.3265C50.8496+52.7201+47.7944+53.3633+44.364+53.3633L32.4649+53.3633L32.4649+37.0691L29.7314+37.0691L29.7314+55.8289L44.5784+55.8289C48.4376+55.8289+51.9216+55.0249+54.9231+53.4169C57.9247+51.8089+60.2831+49.6114+61.9983+46.7706C63.6599+43.9298+64.5175+40.7138+64.5175+37.0691C64.5175+33.4779+63.6599+30.2619+61.9983+27.4211Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-104.851+55.8289M105.823+18.3092L103.089+18.3092L85.8301+55.8289L88.7781+55.8289L104.429+21.3108L120.134+55.8289L123.082+55.8289L105.823+18.3092Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-86.2402+55.8289M179.056+18.3092L176.322+18.3092L176.322+50.8977L150.809+18.3092L148.504+18.3092L148.504+55.8289L151.238+55.8289L151.238+23.2404L176.805+55.8289L179.056+55.8289L179.056+18.3092Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-133.629+55.8289M270.117+39.6418C268.723+38.0338+266.686+37.0155+264.007+36.4795C265.882+35.8899+267.437+34.8179+268.562+33.3171C269.688+31.8699+270.278+30.1011+270.278+27.9035C270.278+24.8484+269.098+22.49+266.847+20.8284C264.596+19.1668+261.434+18.3092+257.36+18.3092L241.709+18.3092L241.709+20.6676L257.253+20.6676C260.523+20.6676+263.042+21.3108+264.811+22.5972C266.579+23.8836+267.49+25.706+267.49+28.118C267.49+30.5835+266.579+32.4595+264.811+33.7459C263.042+35.0323+260.523+35.6219+257.253+35.6219L241.709+35.6219L241.709+37.9803L258.432+37.9803C262.023+37.9803+264.811+38.6234+266.686+39.8562C268.562+41.1426+269.527+43.0722+269.527+45.6986C269.527+48.3786+268.562+50.3081+266.686+51.5945C264.811+52.8809+262.077+53.4705+258.432+53.4705L241.709+53.4705L241.709+55.8289L258.432+55.8289C262.988+55.8289+266.418+54.9713+268.777+53.2561C271.082+51.5409+272.261+49.129+272.261+45.913C272.261+43.3938+271.51+41.3034+270.117+39.6418Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-115.018+55.8289M320.403+42.697C323.19+41.893+325.387+40.4994+326.888+38.4626C328.389+36.4259+329.193+33.9603+329.193+30.9587C329.193+27.046+327.853+23.9372+325.173+21.686C322.493+19.4348+318.795+18.3092+314.078+18.3092L300.624+18.3092L300.624+20.7748L314.078+20.7748C318.044+20.7748+321.099+21.686+323.243+23.4548C325.334+25.2236+326.406+27.7427+326.406+30.9587C326.406+34.2283+325.334+36.7475+323.243+38.5162C321.099+40.285+318.044+41.1426+314.078+41.1426L300.624+41.1426L300.624+55.8289L303.358+55.8289L303.358+43.5546L314.078+43.5546C315.15+43.5546+316.383+43.501+317.723+43.2866L326.674+55.8289L329.783+55.8289L320.403+42.697Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-96.4073+55.8289M382.145+35.5683L357.865+35.5683L357.865+37.9803L382.145+37.9803L382.145+35.5683ZM382.145+18.3092L357.865+18.3092L357.865+20.7748L382.145+20.7748L382.145+18.3092ZM357.865+53.3633L357.865+55.8289L382.145+55.8289L382.145+53.3633L357.865+53.3633Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-77.7964+55.8289M462.029+18.3092L459.349+18.3092L447.825+52.1305L436.14+18.3092L433.514+18.3092L421.776+52.0769L410.359+18.3092L407.465+18.3092L420.221+55.8289L423.116+55.8289L434.8+22.1684L446.431+55.8289L449.326+55.8289L462.029+18.3092Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-59.1854+55.8289M512.748+35.5683L488.467+35.5683L488.467+37.9803L512.748+37.9803L512.748+35.5683ZM512.748+18.3092L488.467+18.3092L488.467+20.7748L512.748+20.7748L512.748+18.3092ZM488.467+53.3633L488.467+55.8289L512.748+55.8289L512.748+53.3633L488.467+53.3633Z" />
			<path stroke="url(#LinearGradient)" strokeWidth="1" fill="none" d="M-40.5745+55.8289M562.764+42.697C565.551+41.893+567.748+40.4994+569.249+38.4626C570.75+36.4259+571.554+33.9603+571.554+30.9587C571.554+27.046+570.214+23.9372+567.534+21.686C564.854+19.4348+561.156+18.3092+556.439+18.3092L542.985+18.3092L542.985+20.7748L556.439+20.7748C560.405+20.7748+563.461+21.686+565.604+23.4548C567.695+25.2236+568.767+27.7427+568.767+30.9587C568.767+34.2283+567.695+36.7475+565.604+38.5162C563.461+40.285+560.405+41.1426+556.439+41.1426L542.985+41.1426L542.985+55.8289L545.719+55.8289L545.719+43.5546L556.439+43.5546C557.511+43.5546+558.744+43.501+560.084+43.2866L569.035+55.8289L572.144+55.8289L562.764+42.697Z" />
		  </svg>
		</SvgLines>
      </header>
    </div>
  );
}

export default App;
